import { GuestPageResponse, GuestPageExtraData, GuestSettingsResponse } from 'lib/api/types'
import { createContext, JSX, ReactNode, useCallback, useState } from 'react'
import { GuestQuote } from 'types'

type Props = {
  children: ReactNode
}

export type GuestOrderContextType = {
  guestPageExtraData: GuestPageExtraData | null
  guestPagePath: string | null
  guestQuote: GuestQuote | null
  guestSettings: GuestSettingsResponse | null
  setGuestOrder: (assetsResponse: GuestPageResponse) => void
  setGuestOrderSettings: (settingsResponse: GuestSettingsResponse) => void
  setGuestPagePath: (path: string) => void
}

const GuestOrderContext = createContext<GuestOrderContextType | undefined>(undefined)

const GuestOrderProvider = ({ children }: Props): JSX.Element => {
  const [guestQuote, setGuestQuote] = useState<GuestQuote | null>(null)
  const [guestPageExtraData, setGuestPageExtraData] = useState<GuestPageExtraData | null>(null)
  const [guestPagePath, setGuestPagePath] = useState<string | null>(null)
  const [guestSettings, setGuestSettings] = useState<GuestSettingsResponse | null>(null)

  const setGuestOrder = useCallback((res: GuestPageResponse) => {
    setGuestQuote(res.quote)
    setGuestPageExtraData(res.page_extra_data || null)
    setGuestPagePath(res.page_path)
  }, [])

  const setGuestOrderSettings = useCallback((res: GuestSettingsResponse) => {
    setGuestSettings(res)
  }, [])

  return (
    <GuestOrderContext.Provider
      value={{
        guestPageExtraData,
        guestPagePath,
        guestQuote,
        guestSettings,
        setGuestOrder,
        setGuestOrderSettings,
        setGuestPagePath,
      }}
    >
      {children}
    </GuestOrderContext.Provider>
  )
}

export { GuestOrderProvider, GuestOrderContext }
