import { useEffect, useState, createContext, useCallback, useContext, useMemo, useRef } from 'react'

import { BrandParameter } from 'types'
import { getSettingsForPath } from 'lib/api/setting'
import { hexToCssHsl } from 'utils/cssHexToHSL'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { routesForApp } from '../../Routes'
import { MixpanelEvents, mixpanelTrack } from 'lib/api/mixpanel/track'
import { getURL } from 'utils/getURL'

import SignInIllustration from 'images/SignIn-Illustration.svg'
import Logo from 'images/ReturnCenterLogo.svg'
import MobileLogo from 'images/ReturnCenterLogoMobile.svg'
import { GuestPageExtraData, InventoryTypeResponse } from 'lib/api/types'
import { Loader } from 'components/Loader'
import { getGuestToken, removeGuestToken, setGuestToken } from 'utils/guestToken'
import { getLocalToken } from 'utils/localToken'

type ContextValue = {
  appSettingsPath: string
  cleanup: () => void
  loginImage: string
  logo: string
  mobileLogo: string
  pageData?: GuestPageExtraData
  paradigmId: number | null
  promoCode: string | null
  promoCodeId: number | null
  setParadigmId: React.Dispatch<React.SetStateAction<number | null>>
  setPromoCode: React.Dispatch<React.SetStateAction<string | null>>
  setPromoCodeId: React.Dispatch<React.SetStateAction<number | null>>
  theme?: BrandParameter
}

const AppSettingsContext = createContext<ContextValue | undefined>(undefined)

type StaticDataProviderProps = {
  brandingParameters?: BrandParameter
  children: React.ReactNode
}

type GuestIntakeWhoOption = 'business' | 'personal'
type GuestIntakeWhatOption = InventoryTypeResponse
type GuestIntakeWhereOption = 'USA' | 'Other'

export type GuestIntakeOptions = {
  path: string
  token: string | null
  what: GuestIntakeWhatOption | null
  where: GuestIntakeWhereOption | null
  who: GuestIntakeWhoOption | null
}

const AppSettingsProvider = ({
  brandingParameters,
  children,
}: StaticDataProviderProps): JSX.Element => {
  const history = useHistory()
  const { pathname, search: queryString } = useLocation()
  const { brand_name, flow_name } = useParams<{ brand_name?: string; flow_name?: string }>()

  const [appSettingsPath, setAppSettingsPath] = useState(() => {
    const brandPath = brand_name && !routesForApp.includes(`/${brand_name}`) ? `/${brand_name}` : ''
    const flowPath = flow_name && !routesForApp.includes(`/${flow_name}`) ? `/${flow_name}` : ''
    return routesForApp.includes(`${brandPath}${flowPath}`) ? '' : `${brandPath}${flowPath}`
  })
  const processedAppSettingsPath = useRef('')

  const [pageData, setPageData] = useState<GuestPageExtraData>()

  const [theme, setTheme] = useState<BrandParameter>()
  const [logo, setLogo] = useState(Logo)
  const [mobileLogo, setMobileLogo] = useState(MobileLogo)
  const [loginImage, setLoginImage] = useState(SignInIllustration)
  const [promoCode, setPromoCode] = useState<string | null>(null)
  const [promoCodeId, setPromoCodeId] = useState<number | null>(null)
  const [paradigmId, setParadigmId] = useState<number | null>(null)
  const [loadingStyles, setLoadingStyles] = useState<boolean>(!!appSettingsPath)
  const [showLoader, setShowLoader] = useState<boolean>(false)

  const cleanup = useCallback(() => {
    sessionStorage.removeItem('isSingleKit')
    sessionStorage.removeItem('wasRedirectedToCreateAccount')

    setAppSettingsPath('')
    setPromoCode(null)
    setPromoCodeId(null)
    setParadigmId(null)
  }, [])

  const setDefaultTheme = () => {
    setLogo(Logo)
    setMobileLogo(MobileLogo)
    setLoginImage(SignInIllustration)

    /** Setting font and font color are currently unused */
    /** Login image is set on the login component */
    document.documentElement.style.setProperty('--color-navbar', '#fff')
    document.documentElement.style.setProperty('--color-primary-h', '191')
    document.documentElement.style.setProperty('--color-primary-s', '97%')
    document.documentElement.style.setProperty('--color-primary-l', '41%')
  }

  const updateThemeState = (theme: BrandParameter) => {
    setTheme(theme)
    if (theme.logo) {
      const logoURL = getURL(theme.logo)
      setLogo(logoURL)
      setMobileLogo(logoURL)
    }
    if (theme.loginImage) {
      setLoginImage(getURL(theme.loginImage))
    }
    if (theme.navigationBarColor) {
      document.documentElement.style.setProperty('--color-navbar', theme.navigationBarColor)
    }
    if (theme.primaryColor) {
      const hslValues = hexToCssHsl(theme.primaryColor)
      if (hslValues) {
        document.documentElement.style.setProperty('--color-primary-h', hslValues.h)
        document.documentElement.style.setProperty('--color-primary-s', hslValues.s)
        document.documentElement.style.setProperty('--color-primary-l', hslValues.l)
      }
    }
  }

  useEffect(() => {
    const getAppSettings = async (settingsParameters: GuestIntakeOptions) => {
      setLoadingStyles(true)

      try {
        const settings = await getSettingsForPath(settingsParameters)

        if (settings.type === 'theme') {
          updateThemeState(settings.branding_parameters)
          if (settings.navigation_parameters) {
            if (settings.navigation_parameters.page_data) {
              setPageData(settings.navigation_parameters.page_data)
            }
            if (settings.token) {
              setGuestToken(settings.token)
            } else {
              removeGuestToken()
            }

            if (settings.navigation_parameters.page_path) {
              history.replace(settings.navigation_parameters.page_path)
            }
          }
        } else if (settings.type === 'promo') {
          setPromoCode(settings.promoCode)
          setPromoCodeId(settings.promoCodeId)
          setParadigmId(settings.paradigmId)
          if (settings.singleKit) sessionStorage.setItem('isSingleKit', 'true')

          setAppSettingsPath('')
          const newLocation = pathname.replace(settingsParameters.path, '')
          history.replace(newLocation)
        }
      } catch (e) {
        setAppSettingsPath('')
        const newLocation = pathname.replace(settingsParameters.path, '')
        history.replace(newLocation)
      } finally {
        setLoadingStyles(false)
        setShowLoader(false)
      }
    }

    if (appSettingsPath && processedAppSettingsPath.current !== appSettingsPath) {
      const searchParams = new URLSearchParams(queryString)
      const who = searchParams.get('rc-customer') as GuestIntakeWhoOption
      const what = searchParams.get('rc-product') as GuestIntakeWhatOption
      const where = searchParams.get('rc-country') as GuestIntakeWhereOption

      const settingsParams: GuestIntakeOptions = {
        path: appSettingsPath,
        token: getLocalToken() || getGuestToken(),
        what,
        where,
        who,
      }

      processedAppSettingsPath.current = appSettingsPath
      void getAppSettings(settingsParams)
    } else if (brandingParameters) {
      updateThemeState(brandingParameters)
    } else {
      setDefaultTheme()
    }
  }, [appSettingsPath, brandingParameters, history, pathname, queryString])

  useEffect(() => {
    if (promoCode) {
      const trackVisit = async () => {
        await mixpanelTrack(MixpanelEvents.PROMO_LINK_VISITED, {
          promoCode,
        })
      }

      void trackVisit()
    }
  }, [promoCode])

  // Delay showing loader indicator
  useEffect(() => {
    if (loadingStyles) {
      const timerId = setTimeout(() => {
        setShowLoader(true)
        clearTimeout(timerId)
      }, 500)
    }
  }, [loadingStyles])

  const value = useMemo<ContextValue>(
    () => ({
      appSettingsPath,
      cleanup,
      loginImage,
      logo,
      mobileLogo,
      pageData,
      paradigmId,
      promoCode,
      promoCodeId,
      setParadigmId,
      setPromoCode,
      setPromoCodeId,
      theme,
    }),
    [
      appSettingsPath,
      cleanup,
      loginImage,
      logo,
      mobileLogo,
      pageData,
      paradigmId,
      promoCode,
      promoCodeId,
      theme,
    ],
  )

  if (loadingStyles) {
    if (showLoader) {
      return (
        <div className="d-flex flex-column align-items-center mt-5">
          <Loader />
          <h2 className="mt-3">Loading application settings...</h2>
        </div>
      )
    } else {
      return <></>
    }
  } else {
    return <AppSettingsContext.Provider value={value}>{children}</AppSettingsContext.Provider>
  }
}

const useAppSettings = (): ContextValue => {
  const context = useContext(AppSettingsContext)

  if (context === undefined) {
    throw new Error('useAppSettings must be used within an AppSettingsProvider')
  }

  return context
}

export { AppSettingsProvider, useAppSettings }
