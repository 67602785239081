import { Dispatch, ReactNode, SetStateAction } from 'react'
import { Column, SortingState, Table } from '@devexpress/dx-react-grid'
import { BrandingParameters, InventoryTypeResponse } from 'lib/api/types'

export type Address = {
  address1: string
  address2?: string
  address_id?: number
  city: string
  country?: string
  postal_code: string
  state_province: string
}

export type AddressWithCompany = {
  address: Address
  company?: string
}

export interface AdvancedSearchFilter {
  columnId: number
  relationId?: Relation
  typeId: SearchType
  value: string | number
}

export interface AdyenPaymentSession {
  allowedPaymentMethods: string[]
  amount: {
    currency: string
    value: number
  }
  applicationInfo: {
    adyenLibrary: {
      name: string
      version: string
    }
    merchantApplication: {
      name: string
    }
  }
  channel: string
  countryCode: string
  expiresAt: string
  id: string
  merchantAccount: string
  reference: string
  returnUrl: string
  sessionData: string
  shopperEmail: string
  shopperLocale: string
  shopperReference: string
}

export type Alert = {
  actionRequired: boolean
  alertId: string
  date: string
  message: string
  relativeDate: string
  title: string
  typeId: number
}

export type Asset = {
  asset_id: number
  icon: string
  name: string
}

export type AssetCategory = {
  assets: Asset[]
  collapsed: '0' | '1'
  name: string
}

export enum AssetDetailsType {
  expected = 'expected',
  pickedUp = 'picked_up',
}

export type AssetQuantity = {
  id: number
  quantity?: number
}

export type AssetType = {
  description: string
  serialNumberRequired: boolean
  typeId: number
}

export interface BaseApiResult {
  error?: string
  result: BaseApiResultStatus
}

export type BaseApiResultStatus = 'Success' | 'Failure'

export enum BoolSortDirection {
  asc = 0,
  desc = 1,
}

export type BrandParameter = {
  font?: string
  fontColor?: string
  loginImage: string | null
  logo: string | null
  navigationBarColor: string | null
  primaryColor: string | null
}

export enum ChartType {
  countChart = 'count_chart',
  countChartVertical = 'count_chart_vertical',
  slaBarChart = 'sla_barchart',
}

export type Box = {
  asset_id?: number
  height?: string
  icon?: string
  length?: string
  name?: string
  quantity: number
  weight?: string
  width?: string
}

export type ChildrenProp = { children: ReactNode }

export type ColorKey = Array<{
  color: JobColor
  en: string
}>

export type ColumnExtension = Table.ColumnExtension & SortingState.ColumnExtension

export enum ColumnName {
  actions = 'actions',
  actualDeliveryDate = 'scanpak_extradata.actual_delivery',
  actualDeliveryDateHighlight = 'actual_delivery_date_highlight',
  actualServiceDate = 'scanpak_extradata.scan_pickup_date',
  actualServiceDateHighlight = 'actual_service_date_highlight',
  allOrdersParadigmName = 'paradigm.title',
  allOrdersProgramId = 'program.program_id',
  allOrdersProgramName = 'program.name',
  brandId = 'brand.brand_id',
  brandName = 'brand.brand_name',
  commentCount = 'comment_count',
  commentText = 'comment_text',
  comments = 'comments',
  createDate = 'jobs.create_date',
  createDateDate = 'DATE(jobs.create_date)',
  customer = 'brand_clients.client_name',
  customerReferenceNum = 'jobs.customer_reference_num',
  customerReferenceNum2 = 'jobs.customer_reference_num2',
  deferEditModal = 'defer_edit_modal',
  deleteDestinationModal = 'destination_delete_modal',
  deleteProgram = 'delete_program',
  deleteReportQueue = 'delete_report_queue',
  deleteReportSchedule = 'delete_report_schedule',
  deleteUploadQueue = 'delete_upload_queue',
  destinationAddress = 'job_destinations.address1',
  destinationCity = 'COALESCE(master_job_destinations.city, job_destinations.city)',
  destinationCompany = 'job_destinations.name',
  destinationId = 'job_destinations.destination_id',
  destinationState = 'COALESCE(master_job_destinations.state, job_destinations.state)',
  discrepancyEditModal = 'discrepancy_edit_modal',
  downloadDispatch = 'download_dispatch',
  downloadReportQueue = 'download_report_queue',
  downloadUploadQueue = 'download_processed_file',
  dspEditModal = 'dsp_edit_modal',
  dspJob = 'dell_ars_extradata.dsp_job_id',
  dspSla = 'dell_ars_extradata.dsp_status_code',
  dspStage = 'dell_ars_extradata.dsp_status',
  dspStatusText = 'dell_ars_extradata.dsp_status_text',
  editDestinationModal = 'destination_edit_modal',
  editProgram = 'edit_program',
  editReportSchedule = 'edit_report_schedule',
  edpCancelModal = 'edp_cancel_modal',
  edpEditModal = 'edp_edit_modal',
  edpLabelEditModal = 'edp_label_edit_modal',
  email = 'users.user_email',
  epAssetsUploadDate = 'dell_ars_extradata.Rcpt_Rpt_Sent',
  epAssetsUploadDateHighlight = 'ep_assets_upload_date_highlight',
  epAssetsUploadDueDate = 'dell_ars_extradata.Rcpt_Rpt_Due',
  epBrandName = 'ep_brand.brand_name',
  epCommentCount = 'ep_comment_count',
  epComments = 'ep_comments',
  epJob = 'dell_ars_extradata.ep_id',
  epSla = 'dell_ars_extradata.ep_status_code',
  epStage = 'dell_ars_extradata.ep_status',
  epStatusText = 'dell_ars_extradata.ep_status_text',
  jobContact1Name = 'job_contact1.name',
  jobContact1Phone = 'job_contact1.phone',
  jobContact2Name = 'job_contact2.name',
  jobContact2Phone = 'job_contact2.phone',
  jobId = 'jobs.job_id',
  jobLocationAddress = 'job_locations.address1',
  jobLocationCity = 'job_locations.city',
  jobLocationCompany = 'job_locations.company',
  jobLocationCountry = 'job_locations.country',
  jobLocationState = 'job_locations.state',
  jobLocationZip = 'jobs_locations.zip',
  jobStatus = 'job_step_status.display_sequence',
  jobStatusWithAction = 'step_status_name',
  jobStatusWithActionITAD = 'COALESCE(master_job_step_status.status5, job_step_status2.status5, job_step_status.status5)',
  jobStatusWithActionLP = 'job_step_status.status6',
  labelSla = 'dell_ars_track.status_code',
  lpBrandName = 'lp_brand.brand_name',
  lpCommentCount = 'lp_comment_count',
  lpComments = 'lp_comments',
  lpEditModal = 'lp_edit_modal',
  lpJob = 'dell_ars_extradata.lp_job_id',
  lpSla = 'dell_ars_extradata.lp_status_code',
  lpStage = 'dell_ars_extradata.lp_stat_status',
  lpStatusText = 'dell_ars_extradata.lp_status_text',
  notificationCount = 'notification_count',
  onHoldEditModal = 'onhold_edit_modal',
  onHoldReason = 'dell_ars_extradata.hold_reason',
  outboundToLessor = 'dell_ars_extradata.outbound_to_lessor',
  outboundToLessorHighlight = 'outbound_to_lessor_highlight',
  partnerEditModal = 'partner_edit_modal',
  partnerLocationEditModal = 'partner_location_edit_modal',
  podReceiptDate = 'dell_ars_extradata.pod_last_date_rec',
  programName = 'quote.program_name',
  qtyPickedUp = 'qty_picked_up',
  qtyPickedUpHighlight = 'qty_picked_up_highlight',
  qtyReceived = 'qty_received',
  qtyReceivedHighlight = 'qty_received_highlight',
  qtySettled = 'qty_settled',
  qtySettledHighlight = 'qty_settled_highlight',
  quoteCreateDate = 'DATE(quote.create_date)',
  quoteDeleteModal = 'quote_delete_modal',
  quoteEditModal = 'quote_edit_modal',
  quoteId = 'quote.quote_id',
  quoteJobId = 'quote.job_id',
  quoteJobLocationCity = 'jl.city',
  quoteNumber = 'quote.quote_number',
  receiptDateHighlight = 'pod_receipt_date_highlight',
  recoveryManager = 'job_remarketers_name',
  reportQueueId = 'report_queue.queue_id',
  reportQueueProcessed = 'report_queue.processed_date',
  reportQueueStatus = 'report_queue.status_id',
  reportQueueTitle = 'report_queue.title',
  reportScheduleBody = 'report_schedule.body',
  reportScheduleEmailBcc = 'report_schedule.email_bcc',
  reportScheduleEmailCc = 'report_schedule.email_cc',
  reportScheduleEmailTo = 'report_schedule.email_to',
  reportScheduleSubject = 'report_schedule.subject',
  reportScheduleTime = 'report_schedules.schedule_time',
  reportScheduleTypeId = 'report_schedule.type_id',
  reportSchedulesConfigId = 'report_schedules.config_id',
  reportSchedulesName = 'report_schedules.name',
  reportSchedulesScheduleId = 'report_schedules.schedule_id',
  reportSchedulesScheduleType = 'report_schedules.schedule_type',
  rescheduledServiceDate = 'dell_ars_extradata.rescheduled_pickup_date',
  rmEditModal = 'rm_edit_modal',
  ruleEditModal = 'rule_edit_modal',
  ruleSetEditModal = 'ruleset_edit_modal',
  scheduledServiceDate = 'scanpak_schedule.scheduled',
  scheduledServiceDateHighlight = 'scheduled_service_date_highlight',
  settlementDate = 'dell_ars_extradata.Steel_Rpt_Sent',
  settlementDateHighlight = 'settlement_date_highlight',
  settlementDueDate = 'dell_ars_extradata.Settl_Rpt_Due',
  sla = 'jobs.status_code',
  slaRuleDelete = 'rule_delete_modal',
  slaRuleId = 'sla_rule.rule_id',
  slaRuleSetDelete = 'ruleset_delete_modal',
  slaRuleSetId = 'sla_ruleset.set_id',
  slaRuleSetName = 'sla_ruleset.name',
  statusText = 'jobs.status_description',
  trackingId = 'job_tracking_info.tracking_id',
  trackingNumber = 'job_tracking_info.tracking_number',
  trackingNumberUrl = 'tracking_url',
  updateStepAction = 'update_step_action',
  uploadQueueId = 'upload_queue.queue_id',
  uploadQueueStatus = 'upload_queue.status_id',
  uploadQueueStatusId = 'view',
  userDeleteModal = 'user_delete_modal',
  userEditModal = 'user_edit_modal',
  userId = 'users.user_id',
  viewLabel = 'view_label',
}

export type Comment = {
  canResolve: boolean
  commentId: string
  commentType: CommentType
  customerReferenceNum: string
  date: string
  jobId: string
  status?: 'resolved' | 'unresolved'
  text: string
  username: string
}

export enum CommentType {
  dspIssue = 'dsp_issue',
  dspIssueSolved = 'dsp_issue_solved',
  dspNote = 'dsp_note',
  epIssue = 'ep_issue',
  epIssueSolved = 'ep_issue_solved',
  epNote = 'ep_note',
  job = 'job',
  lpIssue = 'lp_issue',
  lpIssueSolved = 'lp_issue_solved',
  lpNote = 'lp_note',
  myIssue = 'my',
  rmIssue = 'rm_issue',
  rmIssueSolved = 'rm_issue_solved',
}

export type Company = {
  brandId: number
  brandName: string
}

export type Contact = {
  email?: string
  first_name: string
  last_name: string
  phone: string
}

export enum ContactType {
  Primary = 1,
  Secondary = 2,
}

export type CountChart = {
  countsAndLabels: CountChartCountAndLabel[]
  footer: string
  title: string
  type: ChartType.countChartVertical | ChartType.countChart
}

export type CountChartCountAndLabel = {
  configId: number
  count: number
  filters: AdvancedSearchFilter[]
  label: string
  path: string
}

export type Country = {
  code2: string
  country: string
  currency: string
}

export type Culture = {
  id: number
  name: string
}

export type CurrentUser = {
  authAction?: {
    data?: {
      accountInfo: {
        address: CompanyAddress
        company?: string
        contact: CompanyContact
      }
    }
    name: string
  }
  billingInfo: CompanyInfo
  brandId: number
  brandingParameters: BrandParameter
  colorKey?: ColorKey
  company?: string
  cultureId: number
  defaultDestinationId: number
  defaultMenuId: number
  defaultPath: string
  destinationRouting: boolean
  email: string
  expiredMessage: string
  firstName: string
  gridConfigIdToPathMap: Record<number, string>
  lastName: string
  locale: string
  loggedInAt: number
  menu: MenuConfig[]
  menuConfigIdCache: MenuConfigIdCache
  notificationIntervalMs: number
  paradigms: Paradigm[]
  parentBrandId: number
  passwordExpired: boolean
  // passwordPolicy: string
  perspectiveId: Perspective
  poiUrl: string
  programs: Program[]
  referenceNumbers: OrderReferenceNumber[]
  templates: {
    name: string
    type_id: number
    url: string
  }[]
  token: string
  tokenExpired: boolean
  w9Url: string
}

export type DashboardChartData = {
  [ChartType.countChart]: CountChart[]
  [ChartType.slaBarChart]: SlaBarChart[]
}

export type DashboardOrder = {
  createdDate: string
  id: string
  shipper: string
  status: string
  statusColor: JobColor
}

export enum DateRangePrefix {
  from = 'From',
  to = 'To',
}

export type Destination = {
  destination_id: number
} & TAPIDeliveryLocationOld

export type DestinationRouting = {
  destination_id: number
  state: string
}

export type Dimension = {
  height: number
  length: number
  width: number
}

export type DispatchRow = (number | string | null)[]

export interface DynamicObject {
  [key: string]: string | number | ReactNode
}

export enum OFieldType {
  DatePicker = 1,
  DateRange = 2,
  Email = 5,
  FileName = 6,
  MultiSelect = 4,
  Select = 3,
}

export type OFieldOption = {
  depends_on?: string[]
  label: string
  value: string
}

export type OFieldValidation = {
  error_message: string
  validation_type: string
  validation_value: string
}

export type OField = {
  column_id: number
  depends_on: string
  field_type_id: OFieldType
  id: number
  label: string
  name: string
  options?: OFieldOption[]
  select_options?: SelectOption[]
  sequence: number
  validations?: OFieldValidation[]
}

export type OFormSection = {
  border_style: string
  description: string
  fields: OField[]
  id: number
  label_style: string
  name: string
  sequence: number
}

export type OForm = {
  description: string
  id: number
  name: string
  sections: OFormSection[]
}

export type Grid = {
  configId: number
  current?: boolean
  form?: OForm
  gridClass?: string
  name: string
}

export enum GridAction {
  QuoteCancel = 'quote_cancel',
  QuoteEdit = 'quote_edit',
}

export interface GridConfig {
  align: 'center' | 'left' | 'right'
  columnId: number
  dataOnly: boolean
  filterable: boolean
  heading: string
  headingAlignment: 'center' | 'left' | 'right'
  hidden: boolean
  highlightColumnId: number
  minimumWidth: string
  name: string
  sequence: number
  sortable: boolean
  tooltip: boolean
  typeId: number
  width: string
}

export type GroupedDashboardChartData = {
  charts: DashboardChartData
  title: string
}

export interface GuestAssetCategory extends Omit<AssetCategory, 'collapsed'> {
  collapsed: boolean
  icon: string
}

export interface GuestAssetQuantity extends Omit<AssetQuantity, 'id'> {
  asset_id: number
}

export type GuestAssetType = {
  asset_id: number
  name: string
}

export interface GuestSettingsAssetParams extends GuestSettingsInventoryParams {
  type: 'unpacked_asset'
}

export interface GuestSettingsBoxParams extends GuestSettingsInventoryParams {
  type: 'box'
}

export type GuestSettingsInventoryParams = {
  asset_categories?: GuestAssetCategory[]
  asset_types?: GuestAssetType[]
  type: InventoryTypeResponse
}

export interface GuestSettingsPalletsParams extends GuestSettingsInventoryParams {
  type: 'pallet'
}

export type GuestSettingsResponse = {
  branding_parameters: BrandingParameters[]
  navigation_parameters?: {
    page_data: {
      country_options: SelectOption[]
    }
    page_path: string
  }
  page_path: string
  result: BaseApiResultStatus
  token: string
}

export interface GuestEmailEntry {
  email: string
  is_editable?: boolean
  label: string
  selected: boolean
}

export type GuestEmailGroup = {
  email_addresses: GuestEmailEntry[]
  label: string
  type: EmailType
}

export type GuestFeedbackQuestion = {
  answer_options: SelectOption[]
  question: string
  question_name: string
}

export type GuestPriceFormValues = {
  accept_terms: boolean
}

export type GuestQuote = {
  assets: GuestQuoteAsset[]
  boxes: GuestQuoteBox[]
  company: GuestQuoteCompany
  delivery_location: Location
  email_groups?: GuestEmailGroup[]
  equipment_type?: string
  job_type?: JobType
  lease_return: GuestLeaseReturn
  pallets: GuestQuotePallet[]
  paradigm: GuestQuoteParadigm
  pickup_location: Location
  price?: number
  products: GuestQuoteProduct[]
  program: GuestQuoteProgram
  quote_id: number
  quote_number: number
  reference_numbers: string[]
  requested_pickup?: GuestRequestedPickupStr
  status: string
  user: OrderResponseContact
}

export interface GuestQuoteAsset extends GuestAssetType {
  quantity: number
  serial_number: string
}

export interface GuestQuoteBox extends GuestBox {
  box_id: number
  weight: number
}

export type GuestQuoteCompany = {
  address: Address
  company_id: number
  name: string
}

export interface GuestQuoteLocation extends Omit<Location, 'company'> {
  location_id: number
  name: string
}

export interface GuestQuotePallet extends GuestPallet {
  pallet_id: number
}

export type GuestQuoteParadigm = {
  name: string
  paradigm_id: number
}

export type GuestQuoteProduct = {
  product_id: number
  quantity: number
  tracking_numbers: GuestQuoteProductTrackingNumber[]
}

export type GuestQuoteProductTrackingNumber = {
  carrier: string
  tracking_number: string
}

export type GuestQuoteProgram = {
  name: string
  program_id: number
}

export type GuestRequestedPickup = {
  end_date: Date
  start_date: Date
}

export type GuestRequestedPickupStr = {
  end_date: string
  start_date: string
}

export type GuestRequestedPickupParams = {
  min_pickup_date: string
  window_size: number
}

export type GuestBox = {
  dimensions: Dimension
  quantity: number
}

export type GuestLeaseReturn = {
  company_id?: number
  company_name?: string
  lease_number: string
  ra_due_date: string
}

export type GuestPallet = {
  asset_id: number
  dimensions: Dimension
  quantity: number
  weight: number
}

export enum GuestUserAction {
  feedback = 'feedback',
  next = 'next',
  previous = 'prev',
  sign_in = 'sign_in',
}

export type ISODateString = string

export enum JobColor {
  purple = 0,
  black = 1,
  green = 2,
  yellow = 3,
  red = 4,
  blue = 5,
  gray = 6,
}

export enum JobType {
  boxes = 'Boxes of devices (Pre-packed)',
  pallets = 'Pallets (Pre-packed)',
  unpackedITDevices = 'Unpacked IT Devices',
}

export type Kit = object

export type KitDescription = {
  body: string
  image?: string
  title?: string
}

export interface KitQuantity extends Omit<AssetQuantity, 'id'> {
  kit_type_id: number
}

export type KitType = {
  description: KitDescription
  dimensions: Dimension
  icon: string
  id: number
  name: string
}

export type Label = {
  dimensions: Dimension
  weight: number
}

export type Lessor = {
  id: string
  name: string
}

// This Location type represents a location with multiple contacts (FE version)
export type Location = {
  address: Address
  company?: string
  contacts: Contact[]
}

// This Location type represents a location with multiple contacts in the API
// response (BE version). Usually we re-map this data into Location type.
export type TAPILocation = {
  address: Address
  company: string
  contact: Contact[]
}

// Most of the UI forms support a single contact only. This type is created with
// single contact to simplify working with UI forms.
export type TLocationSingleContact = {
  address: Address
  company?: string
  contact: Contact
}

// Will be consolidated with TAPILocation after location refactoring
export type TAPIDeliveryLocationOld = {
  address: Address
  company?: string
  contact?: Contact
}

export type MenuConfig = {
  color: string
  configId?: number
  description: string
  grids?: Grid[]
  icon: string
  menu?: MenuConfig[]
  menuId: number
  path: string
}

export type MenuConfigIdCache = { [key: number]: number }

export type TMenuItem = {
  [key: number]: string
}

export type TMenuRoutes = {
  [key: string]: number
}

export interface MetaColumn extends Column {
  headingAlignment: 'left' | 'right' | 'center'
  highlightColumnName?: ColumnName
  tooltip: boolean
}

export type TApiQuoteRAInfo = {
  lessor_name: string
  lessor_reference_numbers: OrderReferenceNumber[]
  minimum_insurance: string
}

export type NewLessorResponse = {
  lessor: Lessor
  result: 'Success'
}

export type Order = Partial<{
  addAssetDetails: boolean
  assets: AssetQuantity[]
  boxes: Box[]
  comment: string
  delivery_location: Location
  emails: Email[]
  inboundPriceId: string
  invoice_id: string
  is_local_pickup: boolean
  is_ra: boolean
  is_ra_notice_ack: boolean
  job_id: string
  outboundPriceId: string
  pallets: Pallet[]
  paradigm: Paradigm
  payment: PaymentType
  pickup_date_end: Date
  pickup_date_start: Date
  pickup_location: Location
  products: Array<ProductWithQuantity>
  ra_information: TApiQuoteRAInfo
  reference_numbers: string[]
  restrict_back_action_for?: number
  return_pallet_label: string
  site_information: TApiOrderSiteInformation
}>

export type OrderReferenceNumber = {
  label: string
  prompt: number
  required: boolean
}

export type OrderResponse = {
  assets_expected: OrderResponseAsset[]
  assets_picked_up: OrderResponseAsset[]
  billto_info?: OrderResponseBillToInfo
  brand_name: string
  dates: OrderResponseDate[]
  delivery_location: OrderResponseLocation
  documents?: OrderResponseDocument[]
  initiator: {
    cell_phone: string
    company: string
    email: string
    name: string
    phone: string
  }
  job_type: string
  pallets_expected: OrderResponsePallet[]
  pallets_picked_up: OrderResponsePallet[]
  paradigm_id: string
  photos: TOrderPhotos
  pickup_location: OrderResponseLocation
  products: OrderResponseProduct[]
  program_name: string
  reference1: string
  reference2: string
  reference3: string
  references: OrderResponseReferences[]
  site_information: TListItem[]
  status: string
  tracking_numbers: OrderResponseTrackingNumber[]
}

export type TOrderPhoto = {
  photo_id: string
}

export type TOrderPhotos = {
  arrival: TOrderPhoto[]
  damage?: TOrderPhoto[]
  pallet: TOrderPhoto[]
}

export type OrderResponseAddress = {
  address1: string
  address2: string
  city: string
  company: string
  country: string
  postal_code: string
  state_province: string
}

export type OrderResponseReferences = {
  label: string
  name: string
  required: string
  value: string
}

export type OrderResponseAsset = {
  asset_id: string
  asset_tag: {
    asset_tag: string
    description: string
    dimensions: string
    quantity: number
    serial_number: string
    total_weight: string
    type: string
    uow: string
    weight: string
  }[]
  description: string
  dimensions: string
  id: string
  quantity: string
  serial_number: string
  total_weight: string
  total_weight_raw: string
  type: string
  uow: string
  weight: string
}

export type OrderResponseBillToInfo = {
  address1: string
  address2: string
  city: string
  company: string
  email: string
  first_name: string
  last_name: string
  location_name: string
  phone: string
  postal_code: string
  state_province: string
}

export type OrderResponseContact = {
  email: string
  first_name: string
  last_name: string
  phone: string
}

export type OrderResponseDate = {
  date?: string
  date_range?: [string, string]
  label: string
  name: string
}

export type OrderResponseDocument = {
  create_date: string
  filename: string
  mime_type: string
  name: string
  title: string
  url: string
}

export type OrderResponseLocation = {
  address: OrderResponseAddress
  contacts?: OrderResponseContact[]
}

export type OrderResponsePallet = {
  asset_id: string
  description: string
  height: string
  length: string
  quantity: string
  weight: string
  width: string
}

export type OrderResponseProduct = {
  display_name: string
  icon: string
  product_id: string
  quantity: string
}

export type OrderResponseTrackingNumber = {
  create_date: string
  delivery_date: string
  direction: string
  estimated_delivery_date: string
  label_url: string
  shipper: string
  status: string
  status_date: string
  tracking_number: string
  tracking_url: string
}

export type TOrderPage = {
  description: string
  flow_id: number
  next_flow: number[]
  next_rules: string
  path: string
  prev_flow: number[]
  prev_rules: string
}

export type Pallet = {
  asset_id: number
  height?: string
  icon: string
  length: string
  name: string
  quantity?: string
  weight?: string
  width: string
}

export type ProgramParadigm = Pick<Paradigm, 'name' | 'paradigm_id' | 'payment_terms'> & {
  custom_quote: number
}

export interface Program extends GuestQuoteProgram {
  active: boolean
  description: string
  destination: Destination[]
  display_prices: boolean
  paradigms: ProgramParadigm[]
  payment_terms: boolean
  pickup_buffer_days?: number | null
  program_type: string
  program_type_id: number
  routing_type_id: ProgramDestinationRoutingType
}

export enum ProgramDestinationRoutingType {
  None = 0, // user can enter desination or select from a list of saved destinations
  Single = 1, // a single destination is available; user cannot edit it
  Multiple = 2, // multiple destinations defined; user must select one of them
}

export type PrepaidLabel = {
  height: string
  length: string
  name: string
  prepaid_label_id: number
  weight?: string
  width: string
}

export interface Paradigm extends GuestQuoteParadigm {
  asset_categories: AssetCategory[]
  asset_entry: ParadigmPermission
  assets: Asset[]
  box_entry: ParadigmPermission
  description: string
  icon: string
  invoice_service_charge: number
  pages: TOrderPage[]
  pallet_entry: ParadigmPermission
  payment_terms: boolean
  product_entry: ParadigmPermission
  products: Array<Product>
}

export type ParadigmPermission = {
  allowed: boolean
  required: boolean
}

export enum PaymentType {
  CreditCard = 'CreditCard',
  Invoice = 'Invoice',
  None = 'None',
  ShipMyself = 'ShipMyself', // WARN: This type is for UI use only - do not submit to API
  Terms = 'Terms',
}

export enum Perspective {
  logisticsProvider = 4,
  environmentalPartner = 6,
  electronicsDispositionPartner = 7,
  dataSanitizationPartner = 32,
  dspEpAndLp = 33,
  dellManagement = 12,
  dellRecoveryManager = 17,
  dellSystemsAdministrator = 34,
  environmentalPartnerAndLogisticsProvider = 18,
  emeaEDP = 23,
  dellAdmin = 24,
  lessor = 25,
}

export type Product = {
  dimensions: Dimension
  display_name: string
  help_description: string
  help_icon: string
  icon: string
  product_id: number
  type: 'waybill' | 'box'
}
type WithQuantityAndWeight = { quantity: number; weight?: number }
export type ProductWithQuantity = Product & WithQuantityAndWeight

export type PromoCodeParameters = {
  paradigmId: number
  promoCode: string
  promoCodeId: number
  singleKit: boolean
  type: 'promo'
}

export type QuotePrice = {
  // when user not suppose to see price - back-end might send a string value
  // instead of number to display as price
  amount: number | string
  description: string
  direction?: string
  discount_amount?: number | string
  original_amount?: number | string
  price_id: string
  selected?: 0 | 1 | 2
}

// TODO: merge Quote type with Order type
export type Quote = {
  emails?: TEmailResponse[]
  flow_id: number
  is_local_pickup: boolean
  prices?: QuotePrice[]
  product_prices?: QuotePrice[]
  promo_code?: string
  quote_id: string
  quote_number: string
}

export enum Relation {
  and = 1,
  or = 2,
}

export type Role = {
  name: string
  perspectiveId: Perspective
}

export type SearchAction = {
  buttonText: string
  configId: number
  filters: AdvancedSearchFilter[]
}

export type SearchResultRow = {
  actions: SearchAction[]
  company: string
  customerReferenceNum: string
  customerReferenceNum2: string
  jobId: string
  location: string
  quoteId: number
  quoteNumber: string
  serviceType: string
}

export type SearchResult = {
  jobs: SearchResultRow[]
}

export enum SearchType {
  contains = 8,
  equals = 1,
  greaterThanEqualTo = 4,
  isNotEqualTo = 2,
  isOneOf = 7,
  lessThanEqualTo = 3,
  endsWith = 6,
  beginsWith = 5,
  doesNotContain = 9,
}

export type SelectOption = {
  label: string
  value: number | string
}

export type SlaBarChart = {
  series: SlaBarChartSeries[]
  title: string
}

export type SlaBarChartData = [SlaBarChartDataPoint, SlaBarChartDataPoint, SlaBarChartDataPoint]

type SlaBarChartDataPoint = {
  count: number
  filters: AdvancedSearchFilter[]
  label: string
}

export type SlaBarChartSeries = {
  configId: number
  data: SlaBarChartData
  filters: AdvancedSearchFilter[]
  label: string
  path: string
}

// For now, TListItem = SelectOption but we should not mix them together.
// TListItem is meant to represent a list of items arriving from the server
// which will be displayed in the UI. SelectOption is reserved for dropdowns.
type TListItem = {
  label: string
  value: number | string
}

export type SetErrorMessage = (
  errorMessage: string | null,
  options?: {
    identifier1: number | string
    identifierType1: string
  },
) => void

export type SetUser = Dispatch<SetStateAction<CurrentUser | undefined>>

export enum SortDirection {
  asc = 'asc',
  desc = 'desc',
}

export enum WeightUOM {
  Kilograms = 'KG',
  Pounds = 'LB',
}

export type User = {
  brandId: number
  cellPhone: string
  country: string
  cultureId: number
  defaultDestinationId: number
  email: string
  firstName: string
  lastName: string
  perspectiveId: Perspective
  phone: string
  sendNotificationEmail: boolean
  sendNotificationText: boolean
  userName: string
  weightUom?: WeightUOM
}

export type UserEditLists = {
  companies: Company[]
  countries: Country[]
  cultures: Culture[]
  roles: Role[]
}

export type UsState = {
  name: string
  state: string
}

// CompanyAddress is the same as ApiAdderess, except keys with "_" are cammelCased
// to be consistent with other Form fields
// TODO: replace with Address type
export type CompanyAddress = {
  address1: string
  address2?: string
  addressId?: number
  city: string
  country?: string
  postalCode: string
  stateProvince: string
}

// CompanyContact is the same as ApiContact, except keys with "_" are cammelCased
// to be consistent with other Form fields
export type CompanyContact = {
  cellPhone?: string
  email: string
  firstName: string
  lastName: string
  phone: string
}

// A flat version of UiCompany type
export type CompanyInfo = CompanyAddress &
  CompanyContact & {
    name: string
  }

export type Account = User & {
  billingInfo: CompanyInfo
  companyInfo: CompanyInfo
}

type ApiContact = {
  cell_phone?: string
  email: string
  first_name: string
  last_name: string
  phone: string
}

export type ApiCompany = {
  address: Address
  company: string
  contact: ApiContact
}

export type TFormSubmit<TValues, TResult = void> = (values: TValues) => TResult | Promise<TResult>

export type CommentFormError = {
  commentText?: string | null
}

type DockHours = {
  end_time: string
  start_time: string
}

export enum YesNo {
  No = 'No',
  None = '',
  Yes = 'Yes',
}

export enum AtStartEnd {
  AtEnd = 'At End',
  AtStart = 'At Start',
  None = '',
}

export enum DockOrLiftGate {
  Dock = 'Dock',
  Liftgate = 'Liftgate',
  None = '',
}

export enum SiteInfoCheckinType {
  Dock = 'Dock',
  None = '',
  Other = 'Other',
  Reception = 'Reception',
  Security = 'Security',
}

export type TApiOrderSiteInformation = {
  check_in: string
  dock_hours: DockHours
  dock_or_liftgate: DockOrLiftGate
  eighteen_wheel_truck: boolean
  equipment_close_to_dock: boolean
  onsite_assistance: boolean
  pallet_jack?: boolean
  parking_instructions: string
}

export type EmailType = 'order_receipt' | 'order_confirmation' | 'shipping_status_updates'

export interface UpdateEmailPayload {
  email_addresses: string[]
  type: EmailType
}

type TEmailAddress = {
  email: string
  is_checked: boolean
}

export interface Email {
  email_addresses: TEmailAddress[]
  label: string
  type: EmailType
}

export interface TEmailResponse {
  email_addresses: string[]
  label: string
  type: EmailType
}

export enum AddAccountErrorActions {
  REDIRECT_SIGN_IN = 'REDIRECT_SIGN_IN',
  REDIRECT_UPDATE_ACCOUNT = 'REDIRECT_UPDATE_ACCOUNT',
  SIGN_IN = 'SIGN_IN',
}
