import { useEffect, useState } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'

import { activeSessionCookie, ContextProvider } from '../ContextProvider'
import { authorizationCheck } from '../../lib/api/user'
import { CurrentUser, SetErrorMessage } from '../../types'
import { DialogBox } from '../DialogBox'
import { ErrorMessageDialogBox } from '../ErrorMessageDialogBox'
import { ErrorMessageContextProvider } from '../ErrorMessageContextProvider'
import { getLocalToken, removeLocalToken, setLocalToken } from '../../utils/localToken'
import { report as reportError } from '../../lib/api/error'
import { Router } from '../Router'
import { StaticDataProvider } from 'contexts/StaticDataContext'
import { UnauthenticatedErrorMessageContextProvider } from '../UnauthorizedErrorMessageContextProvider'
import { useRefresh } from '../../hooks/useRefresh'
import { usePolling } from '../../hooks/usePolling'
import { useForceUpdate } from '../../hooks/useForceUpdate'
import { AppSettingsProvider } from 'contexts/AppSettingsContext'

export const App = (): JSX.Element => {
  const [user, setUser] = useState<CurrentUser | undefined>()
  const [initializing, setInitializing] = useState(true)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [unAuthorizedErrorMessage, setUnauthorizedErrorMessage] = useState<string | null>(null)

  const [pollingState, setPollingState] = useState({
    alertCount: 0,
    version: '',
  })

  const { alertCount, version } = pollingState
  const [dialogVisible, setDialogVisible] = useState(false)
  const [refreshAlertTimestamp, refreshAlertCount] = useRefresh()

  const notificationInterval = !user || user?.tokenExpired ? undefined : user.notificationIntervalMs
  // TODO: Move usePolling (and anything else that uses token) under ContextProvider
  // ContextProvider attaches token to any API call. If user session expired,
  // axios api object remains in memory with expired token attached. As user signs
  // in again - ContextProvide will re-attach new token. However, since usePolling()
  // is called before ContextProvider, usePolling will issue first tick() call
  // with expired token leading to 401 error on the call.
  usePolling(notificationInterval, refreshAlertTimestamp, setPollingState)
  useForceUpdate(version, () => setDialogVisible(true))

  const displayAndReportErrorMessage: SetErrorMessage = (errorMessage, options) => {
    if (errorMessage) {
      void reportError({ errorMessage, ...options })
    }
    setErrorMessage(errorMessage)
  }

  useEffect(() => {
    if (!document.cookie.includes(`${activeSessionCookie}=1`)) {
      removeLocalToken()
    }

    const token = getLocalToken()
    if (token === null) {
      setInitializing(false)
      return
    }

    authorizationCheck({ token })
      .then(setUser)
      .catch(() => null)
      .finally(() => {
        setInitializing(false)
      })
  }, [])

  useEffect(() => {
    if (user) {
      setLocalToken(user.token)
      document.cookie = `${activeSessionCookie}=1; path=/; SameSite=Strict`
    } else {
      removeLocalToken()
      document.cookie = `${activeSessionCookie}=0; max-age=0; path=/; SameSite=Strict`
    }
  }, [user])

  return (
    <BrowserRouter basename={import.meta.env.BASE_URL}>
      <Route path="/:brand_name?/:flow_name?">
        <ErrorMessageContextProvider
          errorMessage={errorMessage}
          setErrorMessage={displayAndReportErrorMessage}
        >
          <ContextProvider
            initializing={initializing}
            alertCount={alertCount}
            refreshAlertCount={refreshAlertCount}
            setUser={setUser}
            user={user}
          >
            <StaticDataProvider>
              <AppSettingsProvider brandingParameters={user?.brandingParameters}>
                <UnauthenticatedErrorMessageContextProvider
                  errorMessage={unAuthorizedErrorMessage}
                  setErrorMessage={setUnauthorizedErrorMessage}
                >
                  <Router />
                  <ErrorMessageDialogBox />
                  <DialogBox
                    buttonText="OK"
                    hide={() => window.location.reload()}
                    show={dialogVisible}
                    title="Application Update"
                  >
                    <p>
                      A new version of this app is now available. Please press OK to refresh the
                      application.
                    </p>
                    <p>
                      If you are in the middle of making changes, please note that you will need to
                      re-enter your changes.
                    </p>
                  </DialogBox>
                </UnauthenticatedErrorMessageContextProvider>
              </AppSettingsProvider>
            </StaticDataProvider>
          </ContextProvider>
        </ErrorMessageContextProvider>
      </Route>
    </BrowserRouter>
  )
}
