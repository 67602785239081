import { noAuthApi } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { GuestPageExtraData, InventoryTypeResponse } from 'lib/api/types'
import { BaseApiResult, BrandParameter, PromoCodeParameters } from 'types'

export type BrandingParametersResponse = {
  custom_url?: string
  font: string
  font_color: string
  login_image: string
  login_text?: string
  logo: string
  navigation_bar_color: string
  primary_color: string
}

type GuestIntakeWhoOption = 'business' | 'personal'
type GuestIntakeWhatOption = InventoryTypeResponse
type GuestIntakeWhereOption = 'USA' | 'Other'

type GuestIntakeOptions = {
  path: string
  what: GuestIntakeWhatOption | null
  where: GuestIntakeWhereOption | null
  who: GuestIntakeWhoOption | null
}

type NavigationParametersResponse = {
  page_data?: GuestPageExtraData
  page_path: string
}

type ThemeResponse = {
  branding_parameters: BrandingParametersResponse
}

type NavigationInfoResponse = {
  navigation_parameters: NavigationParametersResponse
  token?: string
}

type PromoCodeResponse = {
  paradigm_id: number
  promo_code: string
  promo_code_id: number
  single_kit: boolean
}

export type SettingsForPathResponse = BaseApiResult &
  Partial<ThemeResponse> &
  Partial<NavigationInfoResponse> &
  Partial<PromoCodeResponse>

export type PathSettings = {
  branding_parameters: BrandParameter
  navigation_parameters?: NavigationParametersResponse
  token?: string
  type: 'theme'
}

export const getSettingsForPath = async (
  settingsParameters: GuestIntakeOptions,
): Promise<PathSettings | PromoCodeParameters> => {
  const { data } = await noAuthApi.get<SettingsForPathResponse>(ENDPOINTS.setting, {
    params: {
      action: 'get_settings_for_path',
      ...settingsParameters,
    },
  })

  if (data.promo_code_id) {
    const { paradigm_id, promo_code, promo_code_id, single_kit } = data as PromoCodeResponse

    return {
      paradigmId: paradigm_id,
      promoCode: promo_code,
      promoCodeId: promo_code_id,
      singleKit: single_kit,
      type: 'promo',
    }
  } else {
    const { branding_parameters } = data as ThemeResponse

    return {
      branding_parameters: {
        font: branding_parameters.font,
        fontColor: branding_parameters.font_color,
        loginImage: branding_parameters.login_image,
        logo: branding_parameters.logo,
        navigationBarColor: branding_parameters.navigation_bar_color,
        primaryColor: branding_parameters.primary_color,
      },
      navigation_parameters: data.navigation_parameters,
      token: data.token,
      type: 'theme',
    }
  }
}
