import { FC, lazy as reactLazy, Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Redirect, useHistory } from 'react-router'

import { SignOut } from 'components/SignOut'

import {
  companyProfile,
  createOrderPath,
  dashboardPath,
  destinationRoutingsPath,
  destinationsPath,
  dispatchSearchPath,
  myAccountPath,
  orderDetailsPath,
  orderPath,
  programPath,
  programsPath,
  quoteDetailsPath,
  reportGridPath,
  reportQueuePath,
  reportSchedulePath,
  reportsPath,
  reportSubmissionPath,
  signOutPath,
  uploadsPath,
  userAdminPath,
  viewsPath,
} from './Routes'

import { Page } from 'components/Page'
import { useAppSettings } from 'contexts/AppSettingsContext'
import { useAppContext } from 'hooks/useAppContext.ts'
import { AddAccountErrorActions } from 'types'
import { GUEST_ROUTES, UNAUTHORIZED_ROUTES } from '../../Routes'
import { ExpiredPasswordForm } from '../ExpiredPasswordForm'
import { ForgotPasswordPage } from '../ForgotPasswordPage'
import { ForgotPasswordSuccessPage } from '../ForgotPasswordSuccessPage'
import { LogInModal } from '../LogInModal'
import { LogInPage } from '../LogInPage'
import { ProtectedRoute } from '../ProtectedRoute'
import { ResetPasswordPage } from '../ResetPasswordPage'
import { UnauthorizedPage } from '../UnauthorizedPage'
import { UnAuthorizedResetPWSuccess } from '../UnauthorizedResetPWSuccess'
import { ErrorBoundary } from './ErrorBoundary'
import NewStyleFlowRouter from 'pages/OrderPages/pages/NewStyleFlowRouter'

const lazy: typeof reactLazy = fn => {
  return reactLazy(() =>
    fn().catch(error => {
      if (error.name === 'ChunkLoadError') window.location.reload()
      throw error
    }),
  )
}

const CompanyProfilePage = lazy(() => import('pages/CompanyProfile'))
const CreateAccountPage = lazy(() => import('pages/CreateAccountPage'))
const CreateOrderPage = lazy(() => import('pages/OrderPages/pages/CreateOrderPage/CreateOrderPage'))
const DashboardPage = lazy(() => import('pages/DashboardPage'))
const DestinationRoutingsPage = lazy(() => import('pages/DestinationRoutingsPage'))
const DestinationsPage = lazy(() => import('pages/DestinationsPage'))
const DispatchSearchResultsPage = lazy(() => import('pages/DispatchSearchResultsPage'))
const DispatchesPage = lazy(() => import('pages/DispatchesPage'))
const ImpersonatePage = lazy(() => import('pages/ImpersonatePage'))
const MyAccountPage = lazy(() => import('pages/MyAccountPage'))
const OrderDetailsPage = lazy(() => import('pages/OrderDetailsPage'))
const OrderPages = lazy(() => import('pages/OrderPages'))
const ProgramPage = lazy(() => import('pages/ProgramPage'))
const ProgramsPage = lazy(() => import('pages/ProgramsPage'))
const QuoteDetailsPage = lazy(() => import('pages/QuoteDetailsPage'))
const RedirectPage = lazy(() => import('pages/RedirectPage'))
const ReportGridPage = lazy(() => import('pages/ReportGridPage'))
const ReportQueuePage = lazy(() => import('pages/ReportQueuePage'))
const ReportSchedulePage = lazy(() => import('pages/ReportSchedulePage'))
const ReportSubmissionPage = lazy(() => import('pages/ReportSubmissionPage'))
const ReportsPage = lazy(() => import('pages/ReportsPage'))
const UpdateAccountPage = lazy(() => import('pages/UpdateAccountPage'))
const UploadsPage = lazy(() => import('pages/UploadsPage'))
const UserAdminPage = lazy(() => import('pages/UserAdminPage'))

const LoadingPage: FC = () => <Page />

export const Router = (): JSX.Element => {
  const { user } = useAppContext()
  const { appSettingsPath } = useAppSettings()
  const history = useHistory()

  // NOTE: This is a quick solution while we are working on a permanant one!
  // Application supports new and old style orders. Each order style must be handled
  // differently due to internal state management. When application handles order
  // URL - it must know order style to route app appropriately. Style of an order
  // depends on a program. However, we do not yet have all the places covered,
  // where we know what style program we have. A significant work is required in
  // <OrderPages> component to allow determination of flow style based on program
  // and handling correct routing. Additionally new style flow can start as a guest
  // and should be handled via <Route> component. However, old style flow is always
  // handled via <ProtectedRoute> component.
  // While this is being figured out, we are using a quick solution to determine
  // order style based on predefined paths
  // NOTE: This code will be removed in the next iteration.
  const slug = history.location.pathname.replace(appSettingsPath, '')
  const orderPathTemplate = slug.replace(/\/order\/([\d]+)/i, '/order/:id')
  const newStyleOrderPath =
    Object.values(GUEST_ROUTES).findIndex(route => route === orderPathTemplate) >= 0

  return (
    <BrowserRouter basename={appSettingsPath} key={appSettingsPath}>
      <ErrorBoundary>
        <Suspense fallback={<LoadingPage />}>
          <LogInModal />

          {user?.authAction?.name === AddAccountErrorActions.REDIRECT_UPDATE_ACCOUNT ? (
            <UpdateAccountPage />
          ) : (
            <Switch>
              <Route exact path={UNAUTHORIZED_ROUTES.createAccount}>
                <CreateAccountPage />
              </Route>

              <Route exact path={UNAUTHORIZED_ROUTES.alternativeLogin}>
                <Redirect to={UNAUTHORIZED_ROUTES.login} />
              </Route>

              <Route exact path={UNAUTHORIZED_ROUTES.login}>
                <LogInPage />
              </Route>

              <Route exact path={UNAUTHORIZED_ROUTES.passwordExpired}>
                <UnauthorizedPage>
                  <ExpiredPasswordForm />
                </UnauthorizedPage>
              </Route>

              <Route exact path={UNAUTHORIZED_ROUTES.forgotPassword}>
                <UnauthorizedPage maxWidth={525}>
                  <ForgotPasswordPage />
                </UnauthorizedPage>
              </Route>

              <Route exact path={UNAUTHORIZED_ROUTES.forgotPasswordSuccess}>
                <UnauthorizedPage>
                  <ForgotPasswordSuccessPage />
                </UnauthorizedPage>
              </Route>

              <Route path={UNAUTHORIZED_ROUTES.impersonate()}>
                <UnauthorizedPage>
                  <ImpersonatePage />
                </UnauthorizedPage>
              </Route>

              <Route exact path={`${UNAUTHORIZED_ROUTES.resetPassword}/:uid`}>
                <UnauthorizedPage>
                  <ResetPasswordPage />
                </UnauthorizedPage>
              </Route>

              <Route exact path={UNAUTHORIZED_ROUTES.resetPasswordSuccess}>
                <UnauthorizedPage>
                  <UnAuthorizedResetPWSuccess />
                </UnauthorizedPage>
              </Route>

              <Route path={UNAUTHORIZED_ROUTES.redirect()}>
                <RedirectPage />
              </Route>

              {newStyleOrderPath ? (
                <Route path={orderPath}>
                  <NewStyleFlowRouter />
                </Route>
              ) : (
                <ProtectedRoute path={orderPath}>
                  <OrderPages />
                </ProtectedRoute>
              )}

              <ProtectedRoute path={createOrderPath} exact>
                <CreateOrderPage />
              </ProtectedRoute>
              <ProtectedRoute path={dashboardPath} exact>
                <DashboardPage />
              </ProtectedRoute>
              <ProtectedRoute path={destinationRoutingsPath} exact>
                <DestinationRoutingsPage />
              </ProtectedRoute>
              <ProtectedRoute path={destinationsPath} exact>
                <DestinationsPage />
              </ProtectedRoute>
              <ProtectedRoute path={dispatchSearchPath} exact>
                <DispatchSearchResultsPage />
              </ProtectedRoute>
              <ProtectedRoute path={myAccountPath} exact>
                <MyAccountPage />
              </ProtectedRoute>
              <ProtectedRoute path={orderDetailsPath()} exact>
                <OrderDetailsPage />
              </ProtectedRoute>
              <ProtectedRoute path={quoteDetailsPath()} exact>
                <QuoteDetailsPage />
              </ProtectedRoute>
              <ProtectedRoute path={reportQueuePath} exact>
                <ReportQueuePage />
              </ProtectedRoute>
              <ProtectedRoute path={userAdminPath} exact>
                <UserAdminPage />
              </ProtectedRoute>
              <ProtectedRoute path={viewsPath}>
                <DispatchesPage />
              </ProtectedRoute>
              <ProtectedRoute path={reportSchedulePath} exact>
                <ReportSchedulePage />
              </ProtectedRoute>
              <Route path={reportSubmissionPath()}>
                <ReportSubmissionPage />
              </Route>
              <ProtectedRoute path={reportsPath} exact>
                <ReportsPage />
              </ProtectedRoute>
              <ProtectedRoute path={reportGridPath} exact>
                <ReportGridPage />
              </ProtectedRoute>
              <ProtectedRoute path={uploadsPath} exact>
                <UploadsPage />
              </ProtectedRoute>
              <ProtectedRoute path={programsPath} exact>
                <ProgramsPage />
              </ProtectedRoute>
              <ProtectedRoute path={programPath} exact>
                <ProgramPage />
              </ProtectedRoute>

              <ProtectedRoute path={signOutPath} exact>
                <SignOut />
              </ProtectedRoute>

              <ProtectedRoute path={companyProfile} exact>
                <CompanyProfilePage />
              </ProtectedRoute>

              <Redirect exact from="/" to={viewsPath} />
              <Route>
                <Page>
                  <h1>Not Found</h1>
                </Page>
              </Route>
            </Switch>
          )}
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  )
}
