import * as React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { loginPath } from 'components/Router/Routes'
import { MenuConfig } from 'types'

import { HeaderMenu } from './components/HeaderMenu'
import { HeaderToggle } from './components/HeaderToggle'
import styles from './Header.module.scss'
import { useAppSettings } from 'contexts/AppSettingsContext'

export type Props = {
  additionalActions?: React.ReactNode
  alertCount: number
  className?: string
  currentMenuItem: string
  defaultPath: string
  menuItems: MenuConfig[]
  mobileMenuOpen: boolean
  onLoginPage: boolean
  pageTitle?: string
  scrollPosition: number
  toggleMobileMenu: () => void
}

export const HeaderView = ({
  additionalActions,
  alertCount,
  className,
  currentMenuItem,
  defaultPath,
  menuItems,
  mobileMenuOpen,
  onLoginPage,
  pageTitle,
  scrollPosition,
  toggleMobileMenu,
}: Props): JSX.Element => {
  const { logo, mobileLogo } = useAppSettings()
  return (
    <nav
      className={classNames(styles.Header, className, {
        [styles.Scrolled]: scrollPosition > 0,
        [styles.Active]: mobileMenuOpen,
      })}
    >
      <Link to={defaultPath}>
        <img alt="Return Center Logo" className={styles.HeaderLogo} src={logo} />
        <img alt="Return Center Logo" className={styles.HeaderLogoMobile} src={mobileLogo} />
      </Link>

      {pageTitle && <div className={styles.HeaderPageTitle}>{pageTitle}</div>}

      {menuItems.length > 0 ? (
        <>
          <div className="d-flex align-items-center">
            {additionalActions}
            <HeaderToggle active={mobileMenuOpen} toggle={toggleMobileMenu} />
          </div>
          <HeaderMenu
            active={mobileMenuOpen}
            alertCount={alertCount}
            currentItem={currentMenuItem}
            menuItems={menuItems}
          />
        </>
      ) : onLoginPage ? null : (
        <Link className={styles.HeaderLink} to={loginPath}>
          Sign In
        </Link>
      )}
    </nav>
  )
}
