import { useEffect, useRef } from 'react'

import { getAlertCount } from 'lib/api/alert'
import { PollingState } from 'components/ContextProvider'

const MIN_DELAY = 10 * 1000 // 10 seconds minimal delay

export const usePolling = (
  notificationIntervalMs: number | undefined,
  refreshAlertTimestamp: number,
  setPollingState: (state: PollingState) => void,
): void => {
  const intervalId = useRef<NodeJS.Timeout | null>(null)
  const lastManualRefreshTimestamp = useRef(0)

  const newIntervalDelay = !notificationIntervalMs
    ? notificationIntervalMs
    : Math.max(notificationIntervalMs, MIN_DELAY)
  const currentIntervalDelay = useRef<number | undefined>(newIntervalDelay)

  useEffect(() => {
    // Define function that gets the alerts count
    const tick = () => {
      void getAlertCount().then(({ count, version }) => {
        setPollingState({
          alertCount: count,
          version,
        })
      })
    }

    const delayChanged = newIntervalDelay !== currentIntervalDelay.current
    const refreshTimestampChanged = refreshAlertTimestamp !== lastManualRefreshTimestamp.current

    // If interval is running but there is parameters update - stop interval
    if (intervalId.current && (delayChanged || refreshTimestampChanged)) {
      clearInterval(intervalId.current)
      intervalId.current = null
    }

    // Run first check right away
    if (newIntervalDelay && refreshTimestampChanged) {
      lastManualRefreshTimestamp.current = refreshAlertTimestamp
      tick()
    }

    // If interval delay is provided and interval is not running - run it
    if (newIntervalDelay && !intervalId.current) {
      currentIntervalDelay.current = newIntervalDelay
      intervalId.current = setInterval(tick, currentIntervalDelay.current)
    }

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current)
        intervalId.current = null
        lastManualRefreshTimestamp.current = 0
        currentIntervalDelay.current = undefined
      }
    }
  }, [newIntervalDelay, refreshAlertTimestamp, setPollingState])
}
