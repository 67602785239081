import { lazy } from 'react'
import { Route, Switch } from 'react-router-dom'

import { GUEST_ROUTES } from 'Routes'
import { GuestOrderProvider } from 'state/context/GuestOrderContext'

const GuestAssetsPage = lazy(() => import('pages/OrderPages/pages/GuestAssetsPage/GuestAssetsPage'))
const GuestBoxesPage = lazy(() => import('pages/OrderPages/pages/GuestBoxesPage/GuestBoxesPage'))
const GuestCheckoutSummaryPage = lazy(
  () => import('pages/OrderPages/pages/GuestCheckoutSummaryPage/GuestCheckoutSummaryPage'),
)
const GuestCreateAccountPage = lazy(
  () => import('pages/OrderPages/pages/GuestCreateAccountPage/GuestCreateAccountPage'),
)
const GuestFeedbackPage = lazy(
  () => import('pages/OrderPages/pages/GuestFeedbackPage/GuestFeedbackPage'),
)
const GuestKitsPage = lazy(() => import('pages/OrderPages/pages/GuestKitsPage/GuestKitsPage'))
const GuestOrderConfirmationPage = lazy(
  () => import('pages/OrderPages/pages/GuestOrderConfirmationPage/GuestOrderConfirmationPage'),
)
const GuestOutOfServiceAreaConfirmationPage = lazy(
  () =>
    import(
      'pages/OrderPages/pages/GuestOutOfServiceAreaConfirmationPage/GuestOutOfServiceAreaConfirmationPage'
    ),
)
const GuestOutOfServiceAreaPage = lazy(
  () => import('pages/OrderPages/pages/GuestOutOfServiceAreaPage/GuestOutOfServiceAreaPage'),
)
const GuestPalletsPage = lazy(
  () => import('pages/OrderPages/pages/GuestPalletsPage/GuestPalletsPage'),
)
const GuestPaymentPage = lazy(
  () => import('pages/OrderPages/pages/GuestPaymentPage/GuestPaymentPage'),
)
const GuestPickupLocationPage = lazy(
  () => import('pages/OrderPages/pages/GuestPickupLocationPage/GuestPickupLocationPage'),
)
const GuestPricePage = lazy(() => import('pages/OrderPages/pages/GuestPricePage/GuestPricePage'))
const GuestRequestedPickupPage = lazy(
  () => import('pages/OrderPages/pages/GuestRequestedPickupPage/GuestRequestedPickupPage'),
)
const GuestSignInPage = lazy(() => import('pages/OrderPages/pages/GuestSignInPage/GuestSignInPage'))

const NewStyleFlowRouter = (): JSX.Element => {
  return (
    <GuestOrderProvider>
      <Switch>
        <Route path={GUEST_ROUTES.assets}>
          <GuestAssetsPage />
        </Route>

        <Route path={GUEST_ROUTES.boxes}>
          <GuestBoxesPage />
        </Route>

        <Route path={GUEST_ROUTES.kits}>
          <GuestKitsPage />
        </Route>

        <Route path={GUEST_ROUTES.createAccount}>
          <GuestCreateAccountPage />
        </Route>

        <Route path={GUEST_ROUTES.feedback}>
          <GuestFeedbackPage />
        </Route>

        <Route path={GUEST_ROUTES.orderConfirmation}>
          <GuestOrderConfirmationPage />
        </Route>

        <Route path={GUEST_ROUTES.outOfServiceArea}>
          <GuestOutOfServiceAreaPage />
        </Route>

        <Route path={GUEST_ROUTES.outOfServiceAreaConfirmation}>
          <GuestOutOfServiceAreaConfirmationPage />
        </Route>

        <Route path={GUEST_ROUTES.pallets}>
          <GuestPalletsPage />
        </Route>

        <Route path={GUEST_ROUTES.pickupLocation}>
          <GuestPickupLocationPage />
        </Route>

        <Route path={GUEST_ROUTES.requestedPickup}>
          <GuestRequestedPickupPage />
        </Route>

        <Route path={GUEST_ROUTES.checkoutSummary}>
          <GuestCheckoutSummaryPage />
        </Route>

        <Route path={GUEST_ROUTES.price}>
          <GuestPricePage />
        </Route>

        <Route path={GUEST_ROUTES.payment}>
          <GuestPaymentPage />
        </Route>

        <Route path={GUEST_ROUTES.signIn}>
          <GuestSignInPage />
        </Route>
      </Switch>
    </GuestOrderProvider>
  )
}

export default NewStyleFlowRouter
