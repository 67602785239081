/** If any of these routes are modified, the unbrandedRoutes array must also be
 * changed. This is for comparing the url and checking
 * if the url parameter is a brand or path
 */

export const GUEST_ROUTES = {
  assets: '/order/:id/assets',
  boxes: '/order/:id/boxes',
  checkoutSummary: '/order/:id/checkout_summary',
  createAccount: '/order/:id/create_account',
  feedback: '/order/:id/feedback',
  kits: '/order/:id/kits',
  orderConfirmation: '/order/:id/order_confirmation',
  outOfServiceArea: '/order/out_of_service_area',
  outOfServiceAreaConfirmation: '/order/out_of_service_area_confirmation',
  pallets: '/order/:id/pallets',
  payment: '/order/:id/payment',
  pickupLocation: '/order/:id/pickup_location',
  price: '/order/:id/price',
  quoteRequired: '/order/:id/rfq_confirmation',
  requestedPickup: '/order/:id/requested_pickup',
  signIn: '/order/:id/sign_in',
}

export const UNAUTHORIZED_ROUTES = {
  alternativeLogin: '/login',
  createAccount: '/create_account',
  forgotPassword: '/email_password_reset',
  forgotPasswordSuccess: '/email_password_reset_success',
  guest: '/guest',
  impersonate: (id?: string): string => `/impersonate/${id ? encodeURIComponent(id) : ':token'}`,
  login: '/sign_in',
  passwordExpired: '/password_expired',
  redirect: (id?: string): string => `/r/${id ? encodeURIComponent(id) : ':uniqueId'}`,
  resetPassword: '/forgot_password',
  resetPasswordSuccess: '/reset_password_success',
}

export const routesForApp = [
  '/account',
  '/create_account',
  '/create_order',
  '/dashboard',
  '/destination_routings',
  '/destinations',
  '/dispatch_search',
  '/draft_details',
  '/email_password_reset',
  '/email_password_reset_success',
  '/forgot_password',
  '/impersonate',
  '/login',
  '/order',
  '/order_details',
  '/password_expired',
  '/program',
  '/programs',
  '/r',
  '/report_queue',
  '/report_schedule',
  '/report_submission',
  '/reports',
  '/reset_password_success',
  '/sign_in',
  '/sign_out',
  '/tools',
  '/uploads',
  '/user_admin',
  '/views',
]
